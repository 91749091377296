<template>
  <div class="onboarding__container">
    <h1 class="smalltitle" style="padding-bottom: 50px; padding-top: 20px">
      {{ current_user.username }}, we are setting up Carl-bot in
      {{ current_guild.name }}
    </h1>
    <div class="underlined__title">
      <h4 class="smalltitle">REACTION ROLES</h4>
    </div>
    <div>
      <b-card class="onboarding__card" style="margin-bottom: 1px">
        <h4 class="teenytinytitle">Reaction Role Templates</h4>
        <div class="form-group-check prefix__container">
          <div
            class="form-group-check-inner point"
            style="
              border: 1px solid white;
              padding: 5px 7px;
              border-radius: 3px;
              background-color: #515b65;
              margin: 4px;
            "
            @mousedown="enterDefaults('colors')"
          >
            <label class="teenytinytitle point" style="margin-bottom: 0px">
              <input type="radio" value="colors" v-model="rr_mode" />
              <svg class="checker" height="16" width="16">
                <circle cx="8" cy="8" r="3" fill="inherit" stroke="none" />
                <circle
                  cx="8"
                  cy="8"
                  r="6"
                  stroke="inherit"
                  strokeWidth="2"
                  fill="none"
                />
              </svg>
              Colors
            </label>
          </div>
          <div
            class="form-group-check-inner point"
            style="
              border: 1px solid white;
              padding: 5px 7px;
              border-radius: 3px;
              background-color: #515b65;
              margin: 4px;
            "
            @mousedown="enterDefaults('continents')"
          >
            <label class="teenytinytitle point" style="margin-bottom: 0px">
              <input type="radio" value="continents" v-model="rr_mode" />
              <svg class="checker" height="16" width="16">
                <circle cx="8" cy="8" r="3" fill="inherit" stroke="none" />
                <circle
                  cx="8"
                  cy="8"
                  r="6"
                  stroke="inherit"
                  strokeWidth="2"
                  fill="none"
                />
              </svg>
              Continents
            </label>
          </div>
          <div
            class="form-group-check-inner point"
            style="
              border: 1px solid white;
              padding: 5px 7px;
              border-radius: 3px;
              background-color: #515b65;
              margin: 4px;
            "
            @mousedown="enterDefaults('zodiac')"
          >
            <label class="teenytinytitle point" style="margin-bottom: 0px">
              <input type="radio" value="zodiac" v-model="rr_mode" />
              <svg class="checker" height="16" width="16">
                <circle cx="8" cy="8" r="3" fill="inherit" stroke="none" />
                <circle
                  cx="8"
                  cy="8"
                  r="6"
                  stroke="inherit"
                  strokeWidth="2"
                  fill="none"
                />
              </svg>
              Zodiac Signs
            </label>
          </div>
          <div
            class="form-group-check-inner point"
            style="
              border: 1px solid white;
              padding: 5px 7px;
              border-radius: 3px;
              background-color: #515b65;
              margin: 4px;
            "
            @mousedown="enterDefaults('platforms')"
          >
            <label class="teenytinytitle point" style="margin-bottom: 0px">
              <input type="radio" value="platforms" v-model="rr_mode" />
              <svg class="checker" height="16" width="16">
                <circle cx="8" cy="8" r="3" fill="inherit" stroke="none" />
                <circle
                  cx="8"
                  cy="8"
                  r="6"
                  stroke="inherit"
                  strokeWidth="2"
                  fill="none"
                />
              </svg>
              Platforms
            </label>
          </div>
          <div
            class="form-group-check-inner point"
            style="
              border: 1px solid white;
              padding: 5px 7px;
              border-radius: 3px;
              background-color: #515b65;
              margin: 4px;
            "
            @mousedown="enterDefaults('rpg')"
          >
            <label class="teenytinytitle point" style="margin-bottom: 0px">
              <input type="radio" value="rpg" v-model="rr_mode" />
              <svg class="checker" height="16" width="16">
                <circle cx="8" cy="8" r="3" fill="inherit" stroke="none" />
                <circle
                  cx="8"
                  cy="8"
                  r="6"
                  stroke="inherit"
                  strokeWidth="2"
                  fill="none"
                />
              </svg>
              RPG Roles
            </label>
          </div>
          <div
            class="form-group-check-inner point"
            style="
              border: 1px solid white;
              padding: 5px 7px;
              border-radius: 3px;
              background-color: #515b65;
              margin: 4px;
            "
            @mousedown="enterDefaults('pronouns')"
          >
            <label class="teenytinytitle point" style="margin-bottom: 0px">
              <input type="radio" value="pronouns" v-model="rr_mode" />
              <svg class="checker" height="16" width="16">
                <circle cx="8" cy="8" r="3" fill="inherit" stroke="none" />
                <circle
                  cx="8"
                  cy="8"
                  r="6"
                  stroke="inherit"
                  strokeWidth="2"
                  fill="none"
                />
              </svg>
              Pronouns
            </label>
          </div>
          <div
            class="form-group-check-inner point"
            style="
              border: 1px solid white;
              padding: 5px 7px;
              border-radius: 3px;
              background-color: #515b65;
              margin: 4px;
            "
            @mousedown="enterDefaults('league')"
          >
            <label class="teenytinytitle point" style="margin-bottom: 0px">
              <input type="radio" value="league" v-model="rr_mode" />
              <svg class="checker" height="16" width="16">
                <circle cx="8" cy="8" r="3" fill="inherit" stroke="none" />
                <circle
                  cx="8"
                  cy="8"
                  r="6"
                  stroke="inherit"
                  strokeWidth="2"
                  fill="none"
                />
              </svg>
              League of Legends
            </label>
          </div>
        </div>
      </b-card>
      <b-card
        v-show="rr_mode != null"
        class="onboarding__card"
        style="margin-bottom: 1px"
      >
        <h4 class="teenytinytitle">What should the message title say?</h4>
        <b-form-input
          type="text"
          pattern=".{1,512}"
          maxlength="512"
          placeholder="Enter a title"
          class="col-12"
          v-model="embedTitle"
        ></b-form-input>
        <br />
        <h4 class="teenytinytitle">
          Which channel should the message be posted in?
        </h4>

        <multiselect
          v-model="selectedChannel"
          id="mute_role_select"
          track-by="id"
          label="name"
          placeholder="Please select a channel"
          open-direction="bottom"
          :searchable="true"
          :clear-on-select="false"
          :close-on-select="true"
          :max-height="600"
          :show-no-results="false"
          :hide-selected="false"
          :options="textChannelOptions"
          :allow-empty="true"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <font-awesome-icon style="color: #72767d" icon="hashtag" />
              <span class="option__title ml-2">{{ props.option.name }}</span>
            </span>
          </template> </multiselect
        ><br />
        <h4 class="teenytinytitle">What color should the embed accent have?</h4>
        <sketch-picker
          v-if="showColorPicker"
          v-model="embedColor"
          v-on-clickaway="hideColors"
        ></sketch-picker>

        <div
          :style="{
            backgroundColor: embedColor.hex,
            height: '40px',
            width: '40px',
            cursor: 'pointer',
            border: '4px solid #22222a',
            borderRadius: '3px',
          }"
          @click="showColorPicker = !showColorPicker"
        ></div>
      </b-card>
      <b-card class="onboarding__card" style="margin-bottom: 60px">
        <div v-if="rr_mode === null">
          <p>Select a template up top to get started</p>
        </div>
        <div v-else>
          Alright a message will be posted to
          {{
            selectedChannel && selectedChannel.id !== -1
              ? "#" + selectedChannel.name
              : "the channel being created"
          }}
          with these roles
          <br />
          <span
            v-for="role in relevantRoles"
            :key="role.name"
            class="previewRole"
            :style="{ border: `1px solid ${parseColor(role)}` }"
          >
            <span
              class="previewRoleDot align-middle"
              :style="{ backgroundColor: parseColor(role) }"
            ></span>
            <span class="align-middle">&nbsp;{{ role.name }}</span>
          </span>
          <br />
          <b-button
            style="width: 100%; margin: 10px 0px 0px 0px"
            variant="primary"
            @click="createTemplate"
            :disabled="loading"
            >Create Reaction Role
          </b-button>
          <div
            class="prefix__container"
            style="align-items: center; margin-top: 5px"
            v-if="loading"
          >
            <div class="sk-circle-fade">
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
            </div>
            <p
              style="
                justify-content: center;
                margin-bottom: 0px;
                margin-left: 2em;
              "
            >
              Please wait while the roles are being created. This will take a
              few seconds.
            </p>
          </div>
        </div>
      </b-card>
    </div>
    <div class="onboarding__footer">
      <button class="saucy-btn" v-on:click="checkOrGo">Next</button>
      <button class="saucy-btn-alt" v-on:click="goBack">Previous step</button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import { ClientTable, Event } from "vue-tables-2";
import { createTags, VueTagsInput } from "@johmun/vue-tags-input";
import Multiselect from "vue-multiselect";
import { Sketch } from "vue-color";
import { mixin as clickaway } from "vue-clickaway";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};

Vue.use(VueNotifications, options);

export default {
  name: "dashboard",
  components: {
    Callout,
    cSwitch,
    VueTagsInput,
    Multiselect,
    "sketch-picker": Sketch,
  },
  mixins: [clickaway],
  data: function () {
    return {
      muterole: null,
      rr_mode: null,
      selectedChannel: null,
      createdChannel: null,
      embedTitle: "",
      embedColor: { hex: "#000" },
      showColorPicker: false,
      loading: false,
      role_templates: {
        colors: {
          title: "Color roles",
          color: "#49d6df",
          roles: [
            { name: "Red", color: 16746640 },
            { name: "Purple", color: 11176191 },
            { name: "Green", color: 8978320 },
            { name: "Pink", color: 16681215 },
            { name: "Orange", color: 16754568 },
            { name: "Yellow", color: 16121736 },
            { name: "Blue", color: 8973055 },
          ],
        },
        continents: {
          title: "Where are you from?",
          color: "#2FBEFF",
          roles: [
            { name: "Europe", color: 3129087 },
            { name: "North America", color: 3129087 },
            { name: "South America", color: 3129087 },
            { name: "Asia", color: 3129087 },
            { name: "Oceania", color: 3129087 },
            { name: "Africa", color: 3129087 },
          ],
        },
        zodiac: {
          title: "What is your zodiac sign?",
          color: "#9B59B6",
          roles: [
            { name: "Aries", color: 10181046 },
            { name: "Taurus", color: 10181046 },
            { name: "Gemini", color: 10181046 },
            { name: "Cancer", color: 10181046 },
            { name: "Leo", color: 10181046 },
            { name: "Virgo", color: 10181046 },
            { name: "Libra", color: 10181046 },
            { name: "Scorpio", color: 10181046 },
            { name: "Sagittarius", color: 10181046 },
            { name: "Capricorn", color: 10181046 },
            { name: "Aquarius", color: 10181046 },
            { name: "Pisces", color: 10181046 },
          ],
        },
        platforms: {
          title: "What platform do you game on?",
          color: "#003791",
          roles: [
            { name: "PC", color: 15428406 },
            { name: "XBOX", color: 1013776 },
            { name: "Playstation", color: 7842047 },
            { name: "Switch", color: 16718123 },
            { name: "Mobile", color: 10468646 },
          ],
        },
        pronouns: {
          title: "What are your pronouns?",
          color: "#ff8000",
          roles: [
            { name: "He/Him", color: 16093196 },
            { name: "She/Her", color: 16501848 },
            { name: "They/Them", color: 11177942 },
            { name: "Ask", color: 7909721 },
          ],
        },
        rpg: {
          title: "What role do you play?",
          color: "#00ccff",
          roles: [
            { name: "Tank", color: 11579355 },
            { name: "DPS", color: 16708712 },
            { name: "Healer", color: 16777214 },
          ],
        },
        league: {
          title: "What role do you play?",
          color: "#FFDC00",
          roles: [
            { name: "Top", color: 11579355 },
            { name: "Jungle", color: 6331462 },
            { name: "Mid", color: 10472666 },
            { name: "Bot", color: 11687766 },
            { name: "Support", color: 6536350 },
            { name: "Fill", color: 4839135 },
          ],
        },
      },
    };
  },
  notifications: {
    showError: {
      type: VueNotifications.types.error,
      title: "Error",
      message: "Could not create reaction role.",
    },
    binzy: {
      type: VueNotifications.types.warn,
      title: "You are being rate limited",
      message:
        "To avoid abuse, you are being rate limited from creating reaction roles. Try again later.",
    },
  },
  computed: {
    current_user() {
      return this.$store.state.discord_user || {};
    },
    current_guild() {
      return this.$store.state.discord_guilds.find(
        (x) => x.id == this.$route.params.guild_id
      );
    },
    relevantRoles() {
      if (!this.rr_mode) {
        return [];
      }
      return this.role_templates[this.rr_mode].roles;
    },
    currentRoles() {
      return this.$store.state.oauthState.roles;
    },
    roleOptions() {
      return [
        { name: "Create one for me", id: -1 },
        ...this.$store.state.oauthState.roles,
      ];
    },
    textChannelOptions() {
      return [
        { name: "Create one for me", id: -1 },
        ...this.$store.state.oauthState.channels,
      ];
    },
    groupedChannels() {
      const channels = this.$store.state.oauthState.channels || [];
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized",
          },
          channels: [],
        },
      ];
      let currentGroup = groups[0];
      for (let chn of channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
        }
      }
      for (let chn of channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find((g) => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    },
  },
  methods: {
    async checkOrGo() {
      const guild_id = this.$route.params.guild_id;
      this.$router.push(`/onboarding/${guild_id}/moderation`);
    },
    async createTemplate() {
      this.loading = true;
      const guild_id = this.$route.params.guild_id;
      let res;
      try {
        res = await this.axios.post(
          `/api/v1/servers/${guild_id}/onboarding/reactionroles`,
          {
            color: this.embedColor.hex,
            title: this.embedTitle,
            channel_id:
              this.selectedChannel.id === -1 ? null : this.selectedChannel.id,
            template: this.rr_mode,
          }
        );
      } catch (e) {
        this.loading = false;
        if (e.response.status === 429) {
          this.binzy();
        } else {
          const body = e.response.data;
          try {
            this.showError({
              message: body.human || "An unknown error has occurred",
            });
          } catch {
            this.showError();
          }
        }
        return;
      }

      this.loading = false;
      if (res.status !== 200) {
        this.showError();
        return;
      }
      if (res.data.msg === null || res.data.msg.channel === null) {
        return;
      }
      const channel = res.data.msg.channel;
      this.$store.commit("newChannel", channel);
      this.selectedChannel = channel;
    },
    goBack() {
      const guild_id = this.$route.params.guild_id;

      this.$router.push(`/onboarding/${guild_id}/welcome`);
    },
    parseColor(r) {
      return r.color ? "#" + r.color.toString(16).padStart(6, "0") : "#efefef";
    },
    intToHex(int) {
      if (!int) {
        return "eee";
      }
      return int.toString(16).padStart(6, "0");
    },
    hideColors() {
      this.showColorPicker = false;
    },
    enterDefaults(mode) {
      this.rr_mode = mode;
      if (!this.rr_mode) {
        return [];
      }
      if (!this.selectedChannel) {
        this.selectedChannel = this.createdChannel
          ? this.createdChannel
          : { name: "Create one for me", id: -1 };
      }
      this.embedTitle = this.role_templates[this.rr_mode].title;
      this.embedColor = {
        hex: this.role_templates[this.rr_mode].color || "#49d6df",
      };
    },

    fetchConfig() {
      this.$Progress.start();
      this.$store.commit("onboardingStage", 2);
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.fetchConfig();
  },
  created: function () {
    this.fetchConfig();
  },
};
</script>

<style src="spinkit/spinkit.min.css"></style>
<style scoped>
.prefix__container {
  display: flex;
  flex-direction: unset;
  flex-wrap: wrap;
}
.prefix__example {
  background-color: #292a33;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  font-weight: 500;
  border-radius: 3px;
}
</style>
<style>
.vue-tags-input {
  background: #505b64;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent;
  color: #b7c4c9;
}

div.vue-tags-input {
  background: #505b64 !important;
}

.vue-tags-input .ti-input {
  padding: 4px 10px;
  transition: border-bottom 200ms ease;
  border: 1px solid #222823;
}

/* we cange the border color if the user focuses the input */
.vue-tags-input.ti-focus .ti-input {
  border: 1px solid #22222a;
}

/* some stylings for the autocomplete layer */
.vue-tags-input .ti-autocomplete {
  background: #283944;
  border-top: none;
}

/* the selected item in the autocomplete layer, should be highlighted */
.vue-tags-input .ti-item.ti-selected-item {
  background: #ebde6e;
  color: #283944;
}

/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input ::-moz-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-ms-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-moz-placeholder {
  color: #a4b1b6;
}

/* default styles for all the tags */
.vue-tags-input .ti-tag {
  position: relative;
  background: #292a33;
  color: #eee;
}

/* we defined a custom css class in the data model, now we are using it to style the tag */
.vue-tags-input .ti-tag.custom-class {
  background: transparent;
  border: 1px solid #292a33;
  color: #292a33;
  margin-right: 4px;
  border-radius: 0px;
  font-size: 13px;
}

/* the styles if a tag is invalid */
.vue-tags-input .ti-tag.ti-invalid {
  background-color: #e88a74;
}

/* if the user input is invalid, the input color should be red */
.vue-tags-input .ti-new-tag-input.ti-invalid {
  color: #e88a74;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  text-decoration: line-through;
}

/* if the user presses backspace, the complete tag should be crossed out, to mark it for deletion */
.vue-tags-input .ti-tag:after {
  transition: transform 0.2s;
  position: absolute;
  content: "";
  height: 2px;
  width: 108%;
  left: -4%;
  top: calc(50% - 1px);
  background-color: #000;
  transform: scaleX(0);
}

.vue-tags-input .ti-deletion-mark:after {
  transform: scaleX(1);
}

.onboarding__container {
  display: flex;
  align-items: center;
  background-color: #22222a;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
}
.onboarding__card {
  display: flex;
  width: 50vm;
}
.underlined__title {
  border-bottom: 4px solid #49d6df;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-flex;
  align-self: center;
}

.point {
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
<style scoped>
.previewRole {
  border-radius: 1em;
  padding: 2px 9px 2px 9px;
  margin: 3px;
  display: inline-block;
}
.previewRoleDot {
  display: inline-block;
  height: 1em;
  width: 1em;
  padding-right: 3px;
  border-radius: 50%;
}
.align-middle {
  vertical-align: middle;
}
</style>